import React from 'react';
import Navbar from '../components/navBar';
import { Wrench } from 'lucide-react';
import Footer from '../components/Footer';

const MaintenancePage = () => {
  return (
    <div className="min-h-screen bg-gray-900">
      <Navbar />
      <main className="container mx-auto px-4 py-12 md:py-24">
        <div className="flex flex-col items-center justify-center space-y-8 text-center">
          <div className="animate-bounce">
            <Wrench className="w-16 h-16 md:w-24 md:h-24 text-blue-500" />
          </div>
          
          <h1 className="text-4xl md:text-6xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
            Under Maintenance
          </h1>
          
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 blur-xl opacity-20 animate-pulse"></div>
            <p className="relative text-lg md:text-xl text-gray-300 max-w-2xl">
              We're currently upgrading our systems to bring you an even better experience.
              Please check back soon!
            </p>
          </div>
          
          <div className="flex flex-col md:flex-row gap-4 mt-8">
            <div className="p-6 bg-gray-800 rounded-lg shadow-xl border border-gray-700">
              <h2 className="text-xl font-semibold text-blue-400 mb-2">Expected Downtime</h2>
              <p className="text-gray-300">2-3 Hours</p>
            </div>
            
            <div className="p-6 bg-gray-800 rounded-lg shadow-xl border border-gray-700">
              <h2 className="text-xl font-semibold text-purple-400 mb-2">Status Updates</h2>
              <p className="text-gray-300">Follow us on Twitter</p>
            </div>
          </div>
          
          <div className="w-full max-w-md bg-gray-800 rounded-lg p-4 mt-8">
            <div className="w-full bg-gray-700 rounded-full h-4">
              <div 
                className="bg-gradient-to-r from-blue-500 to-purple-500 h-4 rounded-full animate-pulse"
                style={{ width: '60%' }}
              ></div>
            </div>
            <p className="text-gray-400 mt-2">Maintenance Progress: 60%</p>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default MaintenancePage;