import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex items-center">
            <img
              src="/Olahub.png"
              alt="OLAHUB Logo"
              className="w-auto h-8 md:h-12"
            />
          </div>
          <div className="hidden md:block">
            <ul className="flex space-x-4 lg:space-x-8">
              <NavItem href="/" text="HOME" />
              <NavItem href="/about" text="ABOUT" />
              <NavItem href="/services" text="SERVICES" />
              <NavItem href="/projects" text="PROJECTS" />
              <NavItem href="#articles" text="ARTICLES" />
              <NavItem href="#contact" text="CONTACT" />
              <NavItem href="#blog" text="PERSONAL BLOG" />
            </ul>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <MobileNavItem href="/" text="HOME" />
            <MobileNavItem href="/about" text="ABOUT" />
            <MobileNavItem href="/projects" text="PROJECTS" />
            <MobileNavItem href="#articles" text="ARTICLES" />
            <MobileNavItem href="#contact" text="CONTACT" />
            <MobileNavItem href="#blog" text="PERSONAL BLOG" />
          </div>
        </div>
      )}
    </nav>
  );
};

const NavItem = ({ href, text }) => (
  <li>
    <a
      href={href}
      className="text-sm lg:text-base text-gray-700 hover:text-blue-600 transition-colors duration-300"
    >
      {text}
    </a>
  </li>
);

const MobileNavItem = ({ href, text }) => (
  <a
    href={href}
    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-gray-50 transition-colors duration-300"
  >
    {text}
  </a>
);

export default Navbar;