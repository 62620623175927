import React, { useState } from 'react';
import { Github, Linkedin, Mail, Twitter, ArrowUp } from 'lucide-react';

const Footer = () => {
    const [email, setEmail] = useState('');
    const currentYear = new Date().getFullYear();

    const handleSubscribe = (e) => {
        e.preventDefault();
        console.log(`Subscribed with email: ${email}`);
        setEmail('');
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer className="bg-gray-900 text-gray-300 py-16 border-t border-gray-800">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
                    <div className="col-span-1 md:col-span-2">
                        <img 
                            src="/logo.png" 
                            alt="OLAHUB" 
                            className="h-12 w-auto mb-6 transform hover:scale-105 transition-transform duration-300"
                        />
                        <p className="text-gray-400 mb-6">Elevating digital experiences through innovative web solutions and cutting-edge technology.</p>
                        <div className="flex space-x-6">
                            <a href="https://github.com/olawale021" target="_blank" rel="noopener noreferrer" 
                               className="text-gray-400 hover:text-purple-400 transform hover:scale-110 transition-all duration-300">
                                <Github size={24} />
                            </a>
                            <a href="https://linkedin.com/in/olahub" target="_blank" rel="noopener noreferrer" 
                               className="text-gray-400 hover:text-purple-400 transform hover:scale-110 transition-all duration-300">
                                <Linkedin size={24} />
                            </a>
                            <a href="https://twitter.com/olahub" target="_blank" rel="noopener noreferrer" 
                               className="text-gray-400 hover:text-purple-400 transform hover:scale-110 transition-all duration-300">
                                <Twitter size={24} />
                            </a>
                            <a href="mailto:contact@olahub.com" 
                               className="text-gray-400 hover:text-purple-400 transform hover:scale-110 transition-all duration-300">
                                <Mail size={24} />
                            </a>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-white mb-6">Quick Links</h3>
                        <ul className="space-y-4">
                            <li>
                                <a href="#projects" className="text-gray-400 hover:text-purple-400 transition-colors duration-300">
                                    Projects
                                </a>
                            </li>
                            <li>
                                <a href="#about" className="text-gray-400 hover:text-purple-400 transition-colors duration-300">
                                    About
                                </a>
                            </li>
                            <li>
                                <a href="#services" className="text-gray-400 hover:text-purple-400 transition-colors duration-300">
                                    Services
                                </a>
                            </li>
                            <li>
                                <a href="#contact" className="text-gray-400 hover:text-purple-400 transition-colors duration-300">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-white mb-6">Stay Updated</h3>
                        <form onSubmit={handleSubscribe} className="space-y-4">
                            <input 
                                type="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                placeholder="Your email" 
                                className="w-full px-4 py-3 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-gray-300 placeholder-gray-500"
                                required
                            />
                            <button 
                                type="submit" 
                                className="w-full px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-300 transform hover:scale-105 shadow-lg"
                            >
                                Subscribe
                            </button>
                        </form>
                    </div>
                </div>
                <div className="mt-16 pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
                    <p className="text-gray-400">&copy; {currentYear} Olahub. All rights reserved.</p>
                    <button 
                        onClick={scrollToTop} 
                        className="mt-6 md:mt-0 flex items-center text-gray-400 hover:text-purple-400 transition-all duration-300 group"
                    >
                        Back to top 
                        <ArrowUp size={20} className="ml-2 transform group-hover:-translate-y-1 transition-transform duration-300" />
                    </button>
                </div>
            </div>
        </footer>
    );
};

export default Footer;