import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/navBar';
import Footer from '../components/Footer';
import SocialLink from '../components/SocialLink';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';

export default function Projects() {
    const navigate = useNavigate();
    const projects = [
        { 
            id: 1, 
            title: "SWAP-EX - An item exchange Platform", 
            description: "A full-stack e-commerce solution with Java EE and SQLite with AI integration to predict categories.", 
            imageUrl: "/swapex.png",
            tech: ["Java EE", "SQLite", "AI", "REST API"]
        },
        { 
            id: 2, 
            title: "Android Food Delivery Application", 
            description: "A full-stack food delivery app built with Kotlin for Android, utilizing Room Database.", 
            imageUrl: "/delivery.png",
            tech: ["Kotlin", "Android", "Room DB", "Material Design"]
        },
        { 
            id: 3, 
            title: "Android Food Recipe Application", 
            description: "A recipe app developed with Kotlin for Android, leveraging Room Database to manage and store recipes locally.", 
            imageUrl: "/recipe.png",
            tech: ["Kotlin", "Android", "Room DB", "UI/UX"]
        },
        { 
            id: 4, 
            title: "StepCare - Appointment Booking System", 
            description: "A full-stack healthcare appointment booking system built with Python and Flask, utilizing MongoDB for efficient data management.", 
            imageUrl: "/stepcare.png",
            tech: ["Python", "Flask", "MongoDB", "RESTful API"]
        },
        { 
            id: 5, 
            title: "E-commerce", 
            description: "A full-stack e-commerce platform built with React, MongoDB, Express, and Node.js, designed for efficient data management and seamless user experiences.", 
            imageUrl: "/ecommerce.png",
            tech: ["React", "Node.js", "MongoDB", "Express"]
        },
        { 
            id: 6, 
            title: "Event Ticket Management", 
            description: "Under Construction", 
            imageUrl: "/api/placeholder/250/150",
            tech: ["Coming Soon"]
        }
    ];

    return (
        <div className="min-h-screen flex flex-col bg-gray-900">
            <Navbar />
            <main className="flex-grow container mx-auto px-4 py-16 max-w-7xl">
                <h1 className="text-4xl font-bold mb-16 text-center">
                    <span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                        Personal Projects
                    </span>
                </h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projects.map((project) => (
                        <div
                            key={project.id}
                            className="group bg-gray-800 rounded-xl overflow-hidden border border-gray-700 hover:border-purple-500 transform transition-all duration-300 hover:scale-105 hover:shadow-xl hover:shadow-purple-500/10"
                        >
                            <div className="relative overflow-hidden">
                                <img
                                    src={project.imageUrl}
                                    alt={project.title}
                                    className="w-full h-48 object-cover transform group-hover:scale-110 transition-transform duration-500"
                                />
                                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                            </div>
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-3 text-white group-hover:text-purple-400 transition-colors duration-300">
                                    {project.title}
                                </h2>
                                <p className="text-gray-400 mb-4 line-clamp-2">
                                    {project.description}
                                </p>
                                <div className="flex flex-wrap gap-2 mb-4">
                                    {project.tech.map((tech, index) => (
                                        <span 
                                            key={index}
                                            className="px-3 py-1 text-sm bg-gray-700 text-gray-300 rounded-full"
                                        >
                                            {tech}
                                        </span>
                                    ))}
                                </div>
                                <button className="inline-flex items-center text-purple-400 hover:text-purple-300 transition-colors duration-300">
                                    View Details 
                                    <svg className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </main>
            <div className="fixed left-6 top-1/2 transform -translate-y-1/2 hidden lg:flex flex-col space-y-7">
                <SocialLink href="#" icon={faTwitter} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
                <SocialLink href="#" icon={faLinkedin} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
                <SocialLink href="#" icon={faGithub} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
                <SocialLink href="#" icon={faMedium} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
            </div>
            <Footer />
        </div>
    );
}