import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faInfoCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const MostRecentProjects = () => {
    const navigate = useNavigate();
    const projects = [
        {
            id: 1,
            title: "SWAP-EX - An item exchange Platform",
            description: "A full-stack e-commerce solution with Java EE and SQLite with AI integration to predict categories.",
            image: "/swapex.png",
            detailsLink: "/projects"
        },
        {
            id: 2,
            title: "Android Food Delivery Application",
            description: "A full-stack food delivery app built with Kotlin for Android, utilizing Room Database.",
            image: "/delivery.png",
            detailsLink: "/projects"
        },
        {
            id: 3,
            title: "Android Food Recipe Application",
            description: "A recipe app developed with Kotlin for Android, leveraging Room Database to manage and store recipes locally.",
            image: "/recipe.png",
            detailsLink: "/projects"
        }
    ];

    return (
        <section className="py-20 bg-gray-900">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-center mb-16">
                    <span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                        Most Recent Projects
                    </span>
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {projects.map((project) => (
                        <div key={project.id} 
                             className="group relative bg-gray-800 rounded-xl overflow-hidden transform hover:scale-105 transition-all duration-300 border border-gray-700 hover:border-purple-500">
                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-gray-900 opacity-60 z-10"></div>
                            <img src={project.image} alt={project.title} 
                                 className="w-full h-64 object-cover transform group-hover:scale-110 transition-transform duration-500" />
                            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-20"></div>
                            <div className="relative z-30 p-6">
                                <h3 className="text-xl font-semibold mb-3 text-white">{project.title}</h3>
                                <p className="text-gray-300 mb-4">{project.description}</p>
                                <button
                                    onClick={() => navigate(project.detailsLink)}
                                    className="inline-flex items-center px-4 py-2 bg-purple-500/20 text-purple-400 rounded-lg hover:bg-purple-500/30 transition-all duration-300"
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                    View Details
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-16 text-center">
                    <button
                        onClick={() => navigate('/projects')}
                        className="group inline-flex items-center px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-300 transform hover:scale-105 shadow-lg"
                    >
                        View All Projects
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    </button>
                </div>
            </div>
        </section>
    );
};

export default MostRecentProjects;