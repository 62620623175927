import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendar, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const RecentArticles = () => {
    const articles = [
        {
            id: 1,
            title: "Introduction to Machine Learning Algorithms",
            excerpt: "Explore the basics of machine learning algorithms and their applications in data science...",
            date: "September 5, 2024",
            readTime: "7 min read",
            category: "Machine Learning"
        },
        {
            id: 2,
            title: "Building Scalable Java Applications",
            excerpt: "Discover best practices for creating scalable and maintainable Java apps...",
            date: "June 2, 2024",
            readTime: "7 min read",
            category: "Development"
        },
        {
            id: 3,
            title: "Machine Learning in Python: A Beginner's Guide",
            excerpt: "Get started with machine learning using Python and popular libraries...",
            date: "June 20, 2024",
            readTime: "10 min read",
            category: "Programming"
        }
    ];

    return (
        <section className="py-20 bg-gray-800">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-4xl font-bold text-center mb-16">
                    <span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                        Recent Articles
                    </span>
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {articles.map((article) => (
                        <article key={article.id}
                                className="group bg-gray-900 rounded-xl overflow-hidden transform hover:scale-105 transition-all duration-300 border border-gray-700 hover:border-purple-500">
                            <div className="p-6">
                                <div className="text-purple-400 text-sm mb-3">{article.category}</div>
                                <h3 className="font-semibold text-xl mb-3 text-white group-hover:text-purple-400 transition-colors duration-300">
                                    {article.title}
                                </h3>
                                <p className="text-gray-400 mb-4">{article.excerpt}</p>
                                <div className="flex justify-between items-center text-sm text-gray-500 border-t border-gray-700 pt-4">
                                    <span className="flex items-center">
                                        <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                                        {article.date}
                                    </span>
                                    <span className="flex items-center">
                                        <FontAwesomeIcon icon={faClock} className="mr-2" />
                                        {article.readTime}
                                    </span>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
                <div className="text-center mt-16">
                    <Link to="/articles"
                          className="group inline-flex items-center px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-300 transform hover:scale-105 shadow-lg">
                        View All Articles
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default RecentArticles;