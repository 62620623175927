
import { Routes, Route } from "react-router-dom";


import Homepage from './pages/homepage';
import Projects from "./pages/projects";
import About from "./pages/about";
import Articles from "./pages/articles";
import ProjectDetail from "./pages/projectDetails";
import './App.css';
import MaintenancePage from "./pages/MaintenancePage";

function App() {
  return (
    <div>
      <Routes>
				<Route path="/" element={<MaintenancePage />} />
				<Route path="/about" element={<About />} />
				<Route path="/home" element={<Homepage />} />
				
				<Route path="/projects" element={<Projects />} />
				<Route path="/articles" element={<Articles />} />
        <Route path="/project/:id" element={<ProjectDetail />} />
				{/* <Route path="/article/:slug" element={<ReadArticle />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<Notfound />} /> */}
			</Routes>
    </div>
  );
}

export default App;
