import React from 'react';
import { Mail } from 'lucide-react';
import { faTwitter, faLinkedin, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import Navbar from '../components/navBar';
import SocialLink from '../components/SocialLink';
import Footer from '../components/Footer';

export default function About() {
    const skills = [
        { name: 'Java', category: 'Backend' },
        { name: 'Machine Learning', category: 'AI' },
        { name: 'Agile', category: 'Methodology' },
        { name: 'Swift', category: 'Mobile' },
        { name: 'Python', category: 'Backend' },
        { name: 'Node JS', category: 'Backend' },
        { name: 'JavaScript', category: 'Frontend' },
        { name: 'React', category: 'Frontend' },
        { name: 'Wordpress', category: 'CMS' },
        { name: 'HTML', category: 'Frontend' },
        { name: 'CSS', category: 'Frontend' },
        { name: 'GIT', category: 'Tools' },
        { name: 'Github', category: 'Tools' },
        { name: 'MongoDB', category: 'Database' },
        { name: 'Docker', category: 'DevOps' },
        { name: 'MySQL', category: 'Database' },
        { name: 'Kotlin', category: 'Mobile' }
    ];

    const categories = [...new Set(skills.map(skill => skill.category))];

    return (
        <div className="min-h-screen bg-gray-900">
            <Navbar />
            <div className="max-w-4xl mx-auto p-4 pt-32">
                <section className="mb-16">
                    <div className="relative">
                        <h1 className="text-5xl font-bold mb-8 text-white leading-tight">
                            I'm <span className="bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">Olawale Filani</span>,
                            <br />I live in Birmingham, UK.
                            <br />Where I develop the future.
                        </h1>
                        <div className="absolute -top-8 -right-8 w-24 h-24 bg-gradient-to-br from-purple-500 to-pink-500 rounded-full blur-2xl opacity-20"></div>
                    </div>
                    
                    <p className="text-xl text-gray-300 mb-8 backdrop-blur-sm bg-gray-800/30 p-6 rounded-xl leading-relaxed">
                        I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. I'm always open to new ideas and feedback, believing that collaboration is key to innovation and growth.
                    </p>

                    <div className="mt-8 sm:mt-12 md:fixed md:left-6 md:top-[45%] md:transform md:-translate-y-1/2 flex flex-row md:flex-col space-x-5 md:space-x-0 md:space-y-7">
                        <SocialLink href="#" icon={faTwitter} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
                        <SocialLink href="#" icon={faLinkedin} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
                        <SocialLink href="https://github.com/olawale021" icon={faGithub} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
                        <SocialLink href="#" icon={faMedium} className="text-gray-400 hover:text-purple-400 transition-colors duration-300" />
                    </div>

                    <a href="mailto:olawale@ola-hub.com" 
                       className="inline-flex items-center mt-8 text-gray-300 hover:text-purple-400 transition-all duration-300 group">
                        <Mail size={24} className="mr-2 group-hover:scale-110 transition-transform duration-300" />
                        olawale@ola-hub.com
                    </a>
                </section>

                <section className="mb-16">
                    <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                        Technical Skills
                    </h2>
                    
                    {categories.map(category => (
                        <div key={category} className="mb-8">
                            <h3 className="text-xl font-semibold mb-4 text-gray-300">{category}</h3>
                            <div className="flex flex-wrap gap-3">
                                {skills
                                    .filter(skill => skill.category === category)
                                    .map((skill, index) => (
                                        <span 
                                            key={index} 
                                            className="bg-gray-800 text-gray-300 px-4 py-2 rounded-lg border border-gray-700 hover:border-purple-500 hover:text-purple-400 transition-all duration-300 transform hover:scale-105"
                                        >
                                            {skill.name}
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    ))}
                </section>
            </div>
            <Footer />
        </div>
    );
}